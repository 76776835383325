export const menuArr=[
  {
    icon:'iconfont icon-index',
    name:'我的课程',
    route:'/myClass',
  },
  {
    icon:'iconfont icon-index',
    name:'系统首页',
    route:'/',
  },
  {
    icon:'iconfont icon-icon-test',
    name:'学生管理',
    route:'/studentManage',
  },
  {
    icon:'iconfont icon-zhibo',
    name:'录播管理',
    route:'/classManage',
  },
  {
    icon:'iconfont icon-xianshangmeiti',
    name:'直播管理',
    route:'/liveVideoManage',
  },
  {
    icon:'iconfont icon-xianshangmeiti',
    name:'资源管理',
    children:[
      {
        icon:'',
        name:'题目管理',
        route:'/quizBaseManage',
      },
      {
        icon:'',
        name:'试卷管理',
        route:'/examMgr',
      },
      {
        icon:'',
        name:'视频管理',
        route:'/videoListManage',
      },
    ]
  },
  {
    icon:'iconfont icon-shezhi',
    name:'基础设置',
    children:[
      {
        icon:'',
        name:'员工管理',
        route:'/staffManage',
      },
      {
        icon:'',
        name:'单位信息',
        route:'/coInfo',
      },
      {
        icon:'',
        name:'系统设置',
        route:'/sysCfg',
      },
      {
        icon:'',
        name:'系统日志',
        route:'/sysLog',
      }
    ]
  }
]

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-table',{ref:"table",attrs:{"data":_vm.tableData,"height":_vm.listHeight,"header-cell-style":{ height: '50px', ..._vm.headerCellStyle },"size":"middle","empty-text":"暂无数据"},on:{"selection-change":_vm.handleSelectionChange,"select":_vm.selectionSelect,"select-all":_vm.selectAll}},[_c('el-table-column',{attrs:{"width":"17"}}),_vm._l((_vm.columns),function(item,i){return [(item.type === 'selection')?_c('el-table-column',{key:i,attrs:{"type":"selection","width":"55"}}):(item.type == 'slot')?_c('el-table-column',{key:i,attrs:{"prop":item.propName,"label":item.title,"width":item.width,"class-name":item.slotName,"align":"left","min-width":item.minWidth},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._t(item.slotName,null,{"scope":scope.row,"rowIndex":scope.$index,"propName":item.propName})]}}],null,true)}):(item.type == 'operate')?_c('el-table-column',{key:i,attrs:{"prop":item.propName,"label":item.title,"width":item.width,"align":"left","min-width":item.minWidth,"fixed":item.fixed},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(item.type === 'operate')?_c('div',[_vm._l((item.operateList),function(operate,btnIndex){return [(operate.emitKey == 'moreOper')?_c('el-dropdown',{key:btnIndex},[_c('span',{staticClass:"ft14 el-dropdown-link",staticStyle:{"margin-left":"10px","color":"#3870F6"}},[_vm._v(" 更多 ")]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((operate.moreBtns),function(btn,i){return _c('el-dropdown-item',{key:i,nativeOn:{"click":function($event){_vm.handleClick(
                        btn,
                        Object.assign({}, scope.row, {
                          rowIndex: scope.$index,
                        }),
                      )}}},[_vm._v(_vm._s(btn.emitKey == 'beInvalid' && scope.row.Status == 1 ? '转为正常学生' : btn.label))])}),1)],1):(operate.emitKey == 'variable')?_c('el-button',{key:btnIndex,class:['list_btn', operate.btnColor],attrs:{"type":"text","size":"medium"},on:{"click":function($event){_vm.handleClick(
                    operate.fn(0),
                    Object.assign({}, scope.row, { rowIndex: scope.$index }),
                  )}}},[_vm._v(_vm._s(operate.fn(0).title || scope.row[item.propName])+" ")]):(
                  operate.emitKey != 'del' && operate.emitKey != 'variable'
                )?_c('el-button',{key:btnIndex,class:['list_btn', operate.btnColor],attrs:{"type":"text","size":"medium","disabled":scope.row.ManualChecked==1&&operate.title=='补充答卷'?true:false},on:{"click":function($event){_vm.handleClick(operate, Object.assign({}, scope.row, { rowIndex: scope.row }),)}}},[_vm._v(_vm._s(operate.title || scope.row[item.propName])+" ")]):(['/liveClassTimesManage','/classTimesManage'].includes(_vm.$route.path)&&scope.row.ROW_NUMBER!= scope.row.total)?_c('span',{key:btnIndex}):_c('el-popconfirm',{key:btnIndex,staticStyle:{"margin-left":"10px","margin-right":"10px"},attrs:{"title":"确定删除吗？"},on:{"confirm":function($event){_vm.handleClick(
                    operate,
                    Object.assign({}, scope.row, { rowIndex: scope.row }),
                  )}}},[_c('el-button',{attrs:{"slot":"reference","type":"text","size":"medium"},slot:"reference"},[_vm._v("删除")])],1)]})],2):(item.type === 'wordbreak')?_c('span',{staticClass:"word-nowrap",on:{"click":function($event){return _vm.changeWordBreak($event)}}},[_vm._v(_vm._s(scope.row[_vm.column.props]))]):_c('span',[_vm._v(_vm._s(scope.row[_vm.column.props]))])]}}],null,true)}):(item.type == 'limitOneLine')?_c('el-table-column',{key:i,attrs:{"prop":item.propName,"label":item.title,"class-name":item.type,"width":item.width,"align":"left","min-width":item.minWidth},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"name_cell",staticStyle:{"width":"100%","position":"relative"}},[_vm._v(" "+_vm._s(_vm.getNameCellWidth())+" "),(scope.row.Name.length * 14 > _vm.nameCellWidth)?_c('el-popover',{attrs:{"placement":"top-start","width":"250","trigger":"hover","content":scope.row.Name}},[_c('div',{staticClass:"limit_one_line",attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(scope.row.Name)+" ")])]):_c('span',[_vm._v(_vm._s(scope.row.Name))])],1)]}}],null,true)}):_c('el-table-column',{key:i,attrs:{"prop":item.propName,"label":item.title,"width":item.width,"align":"left","min-width":item.minWidth}})]})],2),(_vm.needPagination)?_c('div',{staticStyle:{"margin-top":"10px","text-align":"right"}},[_c('el-pagination',{attrs:{"background":"","layout":"total, prev, pager, next, sizes, jumper","page-sizes":_vm.pageSizeList,"small":true,"total":_vm.pagination0 && _vm.pagination0.total,"page-size":_vm.pagination0 && _vm.pagination0.pageSize,"current-page":_vm.pagination0 && _vm.pagination0.current},on:{"current-change":_vm.currentChange,"size-change":_vm.pageSizeChange}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }